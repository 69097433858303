import React, { ReactElement, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useMount } from 'react-use';
import { Action, Column, Field, ISelectOption, UIType } from '@zeroedin-tech/zi-common-ui/lib/';
import SchnurTable from '@zeroedin-tech/zi-common-ui/lib/components/SchnurTable/SchnurTable';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib/components/toast/ToastProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import { AlertVariant, Button, Modal, ModalVariant } from '@patternfly/react-core';
import SchnurForm from '@zeroedin-tech/zi-common-ui/lib/components/SchnurForm/SchnurForm';
import { DatabaseServer, TDatabaseServer } from '../api/databaseServer/DatabaseServer';
import { SubheaderContext } from '../layout/Layout';
import PageTitleSubheader from '../layout/subheader/PageTitleSubheader';
import FilterTableLayout from '../layout/FilterTableLayout';

export default function DatabaseServers(): ReactElement {
	const { addToast } = useToast();
	const [setSubheader]: SubheaderContext = useOutletContext();
	const [tableData, setTableData] = useState<TDatabaseServer[]>([]);
	const [tableLoading, setTableLoading] = useState<boolean>(true);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [activeDatabaseServer, setActiveDatabaseServer] = useState<TDatabaseServer>(
		DatabaseServer.Default() as TDatabaseServer
	);
	const [isFormLoading, setIsFormLoading] = useState<boolean>(false);

	const selectedColumns: Column<TDatabaseServer>[] = [
		{
			title: 'Name',
			columnName: 'name',
		},
		{
			title: 'Host',
			columnName: 'host',
		},
		{
			title: 'Driver',
			columnName: 'driver',
		},
		{
			title: 'Port',
			columnName: 'port',
		},
		{
			title: 'Username',
			columnName: 'db_user',
		},
	];

	const actions: Action<TDatabaseServer>[] = [
		{
			name: (
				<>
					Edit <FontAwesomeIcon icon={faPenToSquare} />
				</>
			),
			callback: (item) => {
				setActiveDatabaseServer(item);
				setIsModalOpen(true);
			},
		},
		{
			name: 'Delete',
			callback: (item) => {
				setTableLoading(true);

				DatabaseServer.Delete(item.id)
					.then(() => {
						setTableLoading(false);
						setTableData((prev) => prev.filter((user) => user.id !== item.id));
						addToast('System User deleted successfully.', AlertVariant.success);
					})
					.catch(() => {
						setTableLoading(false);
						addToast('Failed to delete System User.', AlertVariant.danger);
					});
			},
		},
	];

	const formProperties: Field<TDatabaseServer>[] = [
		{
			title: 'Nickname',
			columnName: 'name',
			uiSchema: {
				type: UIType.TEXT,
				helpText: 'An alias for the database server to help you identify it quickly.',
			},
			required: true,
		},
		{
			title: 'Driver',
			columnName: 'driver',
			uiSchema: {
				type: UIType.SELECT,
				options: [
					'MySQL',
					'Oracle',
					'PostgreSQL',
					'Microsoft SQL Server',
					'Microsoft SQL Server - PDO',
					'ODBC',
				].map((driver) => ({
					key: driver,
					value: driver,
				})),
				initialSelection:
					'id' in activeDatabaseServer ? activeDatabaseServer.driver : 'MySQL',
				onSelect: (value: ISelectOption) => {
					return value.key;
				},
			},
			required: true,
		},
		{
			title: 'Host Name',
			columnName: 'host',
			uiSchema: {
				type: UIType.TEXT,
				placeholder: 'database.example.com',
				helpText: 'The hostname or IP address of the database server.',
			},
			required: true,
		},
		{
			title: 'Username',
			columnName: 'db_user',
			uiSchema: {
				type: UIType.TEXT,
			},
			required: true,
		},
		{
			title: 'Password',
			columnName: 'password',
			uiSchema: {
				type: UIType.PASSWORD,
			},
			required: true,
		},
		{
			title: 'Charset',
			columnName: 'charset',
			uiSchema: {
				type: UIType.TEXT,
			},
			required: true,
		},
		{
			title: 'Port',
			columnName: 'port',
			uiSchema: {
				type: UIType.NUMBER,
			},
			required: true,
		},
	];

	useMount(() => {
		setSubheader(
			<PageTitleSubheader
				pageTitle="Administrators"
				pageDescription="Manage your administrators."
			/>
		);

		DatabaseServer.GetAll()
			.then((tableData) => {
				setTableData(tableData);
				setTableLoading(false);
			})
			.catch(() => {
				addToast('Failed to load System Users.', AlertVariant.danger);
			});
	});

	const addButton = (
		<Button
			data-testid={'systemUser-create'}
			variant={'primary'}
			onClick={() => {
				setActiveDatabaseServer(DatabaseServer.Default() as TDatabaseServer);
				setIsModalOpen(true);
			}}
		>
			New System User
		</Button>
	);

	const handleClose = () => {
		setIsModalOpen(false);
	};

	const handleSuccess = (value: TDatabaseServer) => {
		const index = tableData.findIndex((row) => row.id === value.id);

		if (index >= 0) {
			setTableData((prev) => [...prev.slice(0, index), value, ...prev.slice(index + 1)]);
		} else {
			setTableData((prev) => [...prev, value]);
		}

		setIsModalOpen(false);
	};

	const entityTable = (
		<SchnurTable<TDatabaseServer>
			ariaLabel={'Database Servers'}
			columns={selectedColumns}
			data={tableData}
			caption="Database Servers"
			actions={actions}
			loading={tableLoading}
		/>
	);

	return (
		<React.Fragment>
			<Modal
				variant={ModalVariant.medium}
				title="Database Server Management"
				isOpen={isModalOpen}
				onClose={handleClose}
			>
				<SchnurForm<TDatabaseServer>
					title={'Database Server Management'}
					fields={formProperties}
					initialSubject={activeDatabaseServer}
					isLoading={isFormLoading}
					onSubmit={(entity) => {
						setIsFormLoading(true);

						if (entity.id) {
							DatabaseServer.Update(entity)
								.then((updated) => {
									handleSuccess(updated);
								})
								.catch(() => {
									addToast(
										'An error occurred while trying to save the Database Server. Please try again later.',
										AlertVariant.danger
									);
								})
								.finally(() => {
									setIsFormLoading(false);
								});
						} else {
							DatabaseServer.New(entity)
								.then((newDim) => {
									handleSuccess(newDim);
								})
								.catch(() => {
									addToast(
										'An error occurred while trying to save the Database Server. Please try again later.',
										AlertVariant.danger
									);
								})
								.finally(() => {
									setIsFormLoading(false);
								});
						}
					}}
				/>
			</Modal>
			<React.Fragment>
				<FilterTableLayout
					table={entityTable}
					layoutActions={addButton}
				/>
			</React.Fragment>
		</React.Fragment>
	);
}

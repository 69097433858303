import { Icon, ListItem } from '@patternfly/react-core';
import React from 'react';
import { NavLink } from 'react-router-dom';

export interface INavigationLink {
	title: string;
	icon: JSX.Element;
	to: string;
}

interface Props {
	sidebarLinks: INavigationLink[];
}

export default function PageNavigationLinks({ sidebarLinks }: Props) {
	const sidebarItems = [];
	for (const link of sidebarLinks) {
		sidebarItems.push(
			<ListItem key={link.title}>
				<NavLink to={link.to}>
					<span>
						<Icon
							isInline
							style={{ paddingRight: '0.6em' }}
						>
							{link.icon}
						</Icon>
						{link.title}
					</span>
				</NavLink>
			</ListItem>
		);
	}

	return <React.Fragment>{sidebarItems}</React.Fragment>;
}

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/pro-regular-svg-icons';
import { Icon } from '@patternfly/react-core';
import React from 'react';

type Props = {
	onClick: () => void;
};

export default function Notifications(props: Props) {
	return (
		<Icon
			onClick={() => props.onClick()}
			style={{
				width: '1.5rem',
				height: '1.5rem',
				color: '#8A8D90',
				cursor: 'pointer',
			}}
			alt={'profile'}
			isInline
		>
			<FontAwesomeIcon icon={faBell} />
		</Icon>
	);
}

import { Flex, FlexItem, Title, Text } from '@patternfly/react-core';
import React from 'react';

type props = {
	pageTitle: string;
	pageDescription?: string | undefined;
};
export default function PageTitleSubheader({ pageTitle, pageDescription }: props) {
	return (
		<Flex
			style={{ height: '100%' }}
			direction={{ default: 'column' }}
			alignSelf={{ default: 'alignSelfCenter' }}
			justifyContent={{ default: 'justifyContentCenter' }}
		>
			<FlexItem fullWidth={{ default: 'fullWidth' }}>
				<Title
					headingLevel={'h1'}
					style={{ color: 'white' }}
				>
					{pageTitle}
				</Title>
			</FlexItem>
			{pageDescription ? (
				<FlexItem>
					<Text style={{ color: 'white' }}>{pageDescription}</Text>
				</FlexItem>
			) : null}
		</Flex>
	);
}

import React, { ReactElement } from 'react';
import {
	Brand,
	Masthead,
	MastheadMain,
	MastheadContent,
	MastheadBrand,
} from '@patternfly/react-core';
import HeaderContent from './HeaderContent';

type Props = {
	headerText: string;
	logoImg: string;
};
export default function Header(props: Props): ReactElement {
	return (
		<Masthead
			style={{ display: 'inline-block' }}
			backgroundColor={'light'}
		>
			<MastheadMain>
				<MastheadBrand>
					<Brand
						alt={'ZeroedIn Technologies'}
						src={props.logoImg}
					/>
				</MastheadBrand>
				<MastheadContent style={{ display: 'inline-grid' }}>
					<HeaderContent headerText={props.headerText} />
				</MastheadContent>
			</MastheadMain>
		</Masthead>
	);
}

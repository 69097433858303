import axiosConfig from '../axiosConfig';

export interface LoginResponse {
	message: string;
}
export async function Login(username: string, password: string): Promise<LoginResponse> {
	return axiosConfig.instance.post('admin/login', { username, password }).then(() => {
		return { message: 'Successfully logged in.' };
	});
}

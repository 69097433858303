import React, { useState } from 'react';
import { Grid, GridItem, TextInput } from '@patternfly/react-core';
import MagnifyingGlassIcon from '@fortawesome/fontawesome-pro/svgs/regular/magnifying-glass.svg';

export type Props = {
	layoutActions?: React.ReactElement | React.ReactElement[];
	table: React.ReactElement;
};
export default function FilterTableLayout(props: Props) {
	const [filter, setFilter] = useState<string>('');

	const tableWithFilter = React.cloneElement(props.table, {
		filter: filter,
	});

	return (
		<Grid hasGutter>
			<GridItem span={3}>
				<TextInput
					value={filter}
					type="text"
					customIconUrl={MagnifyingGlassIcon}
					placeholder="Filter"
					onChange={(value) => setFilter(value)}
					aria-label="Filter table"
				/>
			</GridItem>

			<GridItem
				span={9}
				style={{ justifySelf: 'end', gap: '1rem' }}
			>
				{props.layoutActions}
			</GridItem>
			<GridItem span={12}>{tableWithFilter}</GridItem>
		</Grid>
	);
}

import { AxiosInstance, AxiosRequestConfig } from 'axios';
import axios from 'axios';
import { AxiosCacheInstance, setupCache } from 'axios-cache-interceptor';
import { getCSRFToken } from './security/CSRFToken';

const url: string = process.env.REACT_APP_API_URL ?? 'https://admin-console.zeroedin.com';
const port: string = process.env.REACT_APP_API_PORT ?? '443';

export interface IZIApiError {
	'message': string;
	'error-id': string;
	'extra': string;
}

function axiosConfig(): {
	baseURL: string;
	timeout: number;
	instance: AxiosCacheInstance;
} {
	const baseURL = `${url}:${port}/`;
	const timeout = 100000;

	const axiosInstance: AxiosInstance = axios.create({
		baseURL,
		timeout,
		withCredentials: true,
		headers: {
			'CSRF-TOKEN': 'CSRF-Token',
		},
	});

	axiosInstance.interceptors.response.use(
		(response) => response,
		async (error) => {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			const originalRequest = error.config as AxiosRequestConfig & { _retry: boolean };

			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			if (error.response?.status === 418 && !originalRequest._retry) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				originalRequest._retry = true;

				try {
					const newCsrfToken = await getCSRFToken();
					axiosInstance.defaults.headers['CSRF-TOKEN'] = newCsrfToken;
					// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/ban-ts-comment
					// @ts-ignore
					originalRequest.headers['CSRF-TOKEN'] = newCsrfToken;

					return axiosInstance(originalRequest);
				} catch (refreshErr) {
					return Promise.reject(refreshErr);
				}
			}

			return Promise.reject(error);
		}
	);

	const instance = setupCache(axiosInstance);

	return {
		baseURL,
		timeout,
		instance,
	};
}

export default axiosConfig();

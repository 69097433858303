import React, { ReactElement, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import PageTitleSubheader from '../layout/subheader/PageTitleSubheader';
import { SubheaderContext } from '../layout/Layout';
import { TextContent, TextVariants, Text, Card, CardBody, CardTitle } from '@patternfly/react-core';

export default function GettingStarted(): ReactElement {
	const [setSubheader]: SubheaderContext = useOutletContext();

	useEffect(() => {
		setSubheader(
			<PageTitleSubheader
				pageTitle="Getting Started"
				pageDescription="Get started with ZeroedIn"
			/>
		);
	}, [setSubheader]);

	return (
		<React.Fragment>
			<Card style={{ height: '100%' }}>
				<CardTitle>
					<h1>
						<TextContent>
							<Text component={TextVariants.h1}>
								Welcome to the Zeroedin Analytics System Administration App
							</Text>
						</TextContent>
					</h1>
				</CardTitle>
				<CardBody>
					<TextContent>
						<Text component={TextVariants.p}>
							The Administrators tab allows you to create, edit, and delete system
							admins.
						</Text>
						<Text component={TextVariants.p}>
							In order to setup a new zone, you must <b>first</b> have a Database
							Server. A data base server is the actual host that will hold zone
							instances, like an RDS host.
						</Text>
						<Text component={TextVariants.p}>
							Database Instances are the zones' app and data databases. You create an
							app and data database when you provision a new zone. During
							provisioning, you will fill out the zone details and select a server
							host for the new app and data dbs to live on.
						</Text>
					</TextContent>
				</CardBody>
			</Card>
		</React.Fragment>
	);
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import '@patternfly/react-core/dist/styles/base.css';
import './index.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Axios } from 'axios';
import LoginPage from './routes/root/login/LoginPage';
import Administrators from './routes/Administrators/Administrators';
import Layout from './layout/Layout';
import GettingStarted from './routes/GettingStarted';
import SystemNotifications from './routes/SystemNotifications';
import DatabaseServers from './routes/DatabaseServers';
import Zones from './routes/Zones';
import Healthcheck from './routes/Healthcheck';
import PasswordReset from './routes/root/passwordReset/PasswordReset';
import NotFound from './routes/root/NotFound';

function axiosLoader() {
	return Axios;
}

export const router = createBrowserRouter([
	{
		path: '/login',
		element: <LoginPage />,
		loader: axiosLoader,
	},
	{
		path: '/admin/system-user/reset-password',
		element: <PasswordReset />,
	},
	{
		path: '/',
		element: <Layout />,
		children: [
			{
				index: true,
				element: <GettingStarted />,
			},
			{
				path: '/administrators',
				element: <Administrators />,
			},
			{
				path: '/getting-started',
				element: <GettingStarted />,
			},
			{
				path: '/system-notifications',
				element: <SystemNotifications />,
			},
			{
				path: '/database-servers',
				element: <DatabaseServers />,
			},
			{
				path: '/zones',
				element: <Zones />,
			},
			{
				path: '/healthcheck',
				element: <Healthcheck />,
			},
		],
	},
	{
		path: '*',
		element: <NotFound />,
	},
]);
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

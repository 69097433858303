import React, { ReactElement, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useMount } from 'react-use';
import {
	Action,
	Column,
	Field,
	timestampToMMDDYYYY,
	UIType,
} from '@zeroedin-tech/zi-common-ui/lib/';
import SchnurTable from '@zeroedin-tech/zi-common-ui/lib/components/SchnurTable/SchnurTable';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib/components/toast/ToastProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import { AlertVariant, Button, Modal, ModalVariant } from '@patternfly/react-core';
import SchnurForm from '@zeroedin-tech/zi-common-ui/lib/components/SchnurForm/SchnurForm';
import { SubheaderContext } from '../layout/Layout';
import PageTitleSubheader from '../layout/subheader/PageTitleSubheader';
import FilterTableLayout from '../layout/FilterTableLayout';
import {
	SystemNotification,
	TSystemNotification,
} from '../api/systemNotification/SystemNotification';

export default function DatabaseServers(): ReactElement {
	const { addToast } = useToast();
	const [setSubheader]: SubheaderContext = useOutletContext();
	const [tableData, setTableData] = useState<TSystemNotification[]>([]);
	const [tableLoading, setTableLoading] = useState<boolean>(true);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [activeSystemNotification, setActiveSystemNotification] = useState<TSystemNotification>(
		SystemNotification.Default() as TSystemNotification
	);
	const [isFormLoading, setIsFormLoading] = useState<boolean>(false);

	const selectedColumns: Column<TSystemNotification>[] = [
		{
			title: 'ID',
			columnName: 'id',
		},
		{
			title: 'Message',
			columnName: 'message',
		},
		{
			title: 'Start Date',
			columnName: 'start_date',
			customAccessor: (item) => {
				return item.end_date ? timestampToMMDDYYYY(item.start_date) : '';
			},
		},
		{
			title: 'End Date',
			columnName: 'end_date',
			customAccessor: (item) => {
				return item.end_date ? timestampToMMDDYYYY(item.end_date) : '';
			},
		},
	];

	const actions: Action<TSystemNotification>[] = [
		{
			name: (
				<>
					Edit <FontAwesomeIcon icon={faPenToSquare} />
				</>
			),
			callback: (item) => {
				setActiveSystemNotification(item);
				setIsModalOpen(true);
			},
		},
		{
			name: 'Delete',
			callback: (item) => {
				setTableLoading(true);

				SystemNotification.Delete(item.id)
					.then(() => {
						setTableLoading(false);
						setTableData((prev) => prev.filter((entity) => entity.id !== item.id));
						addToast('System Notification deleted successfully.', AlertVariant.success);
					})
					.catch(() => {
						setTableLoading(false);
						addToast('Failed to delete System Notification.', AlertVariant.danger);
					});
			},
		},
	];

	const formProperties: Field<TSystemNotification>[] = [
		{
			title: 'Message',
			columnName: 'message',
			uiSchema: {
				type: UIType.TEXT,
			},
			required: true,
		},
		{
			title: 'Start Date',
			columnName: 'start_date',
			uiSchema: {
				type: UIType.DATETIME,
			},
			required: true,
		},
		{
			title: 'End Date',
			columnName: 'end_date',
			uiSchema: {
				type: UIType.DATETIME,
			},
			required: true,
		},
	];

	useMount(() => {
		setSubheader(
			<PageTitleSubheader
				pageTitle="System Notifications"
				pageDescription="Manage your System Notifications."
			/>
		);

		SystemNotification.GetAll()
			.then((tableData) => {
				setTableData(tableData);
				setTableLoading(false);
			})
			.catch(() => {
				addToast('Failed to load System Notification.', AlertVariant.danger);
			});
	});

	const addButton = (
		<Button
			data-testid={'add-button'}
			variant={'primary'}
			onClick={() => {
				setActiveSystemNotification(SystemNotification.Default() as TSystemNotification);
				setIsModalOpen(true);
			}}
		>
			New System Notification
		</Button>
	);

	const handleClose = () => {
		setIsModalOpen(false);
	};

	const handleSuccess = (value: TSystemNotification) => {
		const index = tableData.findIndex((row) => row.id === value.id);

		if (index >= 0) {
			setTableData((prev) => [...prev.slice(0, index), value, ...prev.slice(index + 1)]);
		} else {
			setTableData((prev) => [...prev, value]);
		}

		setIsModalOpen(false);
	};

	const entityTable = (
		<SchnurTable<TSystemNotification>
			ariaLabel={'System Notifications'}
			columns={selectedColumns}
			data={tableData}
			caption="System Notifications"
			actions={actions}
			loading={tableLoading}
		/>
	);

	return (
		<React.Fragment>
			<Modal
				variant={ModalVariant.medium}
				title="System Notification Management"
				isOpen={isModalOpen}
				onClose={handleClose}
			>
				<SchnurForm<TSystemNotification>
					title={'System Notification Management'}
					fields={formProperties}
					initialSubject={activeSystemNotification}
					isLoading={isFormLoading}
					onSubmit={(entity) => {
						setIsFormLoading(true);

						entity.start_date = Math.floor(entity.start_date);
						entity.end_date = Math.floor(entity.end_date);

						if (entity.id) {
							SystemNotification.Update(entity)
								.then((updated) => {
									handleSuccess(updated);
								})
								.catch(() => {
									addToast(
										'An error occurred while trying to update the system notification. Please try again later.',
										AlertVariant.danger
									);
								})
								.finally(() => {
									setIsFormLoading(false);
								});
						} else {
							SystemNotification.New(entity)
								.then((newEntity) => {
									handleSuccess(newEntity);
								})
								.catch(() => {
									addToast(
										'An error occurred while trying to create the system notification. Please try again later.',
										AlertVariant.danger
									);
								})
								.finally(() => {
									setIsFormLoading(false);
								});
						}
					}}
				/>
			</Modal>
			<React.Fragment>
				<FilterTableLayout
					table={entityTable}
					layoutActions={addButton}
				/>
			</React.Fragment>
		</React.Fragment>
	);
}

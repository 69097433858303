import { Flex, FlexItem, Title } from '@patternfly/react-core';
import Settings from './Settings';
import Avatar from './Avatar';
import Notifications from './Notifications';
import React from 'react';

type Props = {
	headerText: string;
};

export default function HeaderContent(props: Props) {
	return (
		<Flex
			justifyContent={{ default: 'justifyContentSpaceBetween' }}
			alignItems={{ default: 'alignItemsCenter' }}
		>
			<FlexItem>
				<Title headingLevel={'h3'}>{props.headerText}</Title>
			</FlexItem>
			<FlexItem>
				<Flex>
					<FlexItem align={{ default: 'alignRight' }}>
						<Settings onClick={() => console.log('clicked settings icon')} />
					</FlexItem>
					<FlexItem>
						<Notifications onClick={() => console.log('clicked notification icon')} />
					</FlexItem>
					<FlexItem>
						<Avatar />
					</FlexItem>
				</Flex>
			</FlexItem>
		</Flex>
	);
}

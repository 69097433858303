import {
	Spinner,
	ActionGroup,
	Alert,
	Button,
	Form,
	FormGroup,
	TextInput,
} from '@patternfly/react-core';
import React, { ReactElement, useEffect, useState } from 'react';
import './LoginPage.css';
import { Login } from '../../../api/login/Login';
import CenteredPage from '../../../layout/CenteredPage';
import axios, { AxiosError } from 'axios';
import axiosConfig, { IZIApiError } from '../../../api/axiosConfig';
import { useNavigate } from 'react-router-dom';
import { getCSRFToken } from '../../../api/security/CSRFToken';

export default function LoginPage(): ReactElement {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState<React.ReactElement | null>();
	const navigate = useNavigate();

	useEffect(() => {
		getCSRFToken()
			.then((token) => {
				axiosConfig.instance.defaults.headers['CSRF-TOKEN'] = token;
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	const login = () => {
		if (loading) {
			return;
		}

		if (username === '' || password === '') {
			setMessage(
				<Alert
					variant="danger"
					isInline
					isPlain
					title="Username and password must be set."
				/>
			);

			return;
		}

		setMessage(null);
		setLoading(true);

		Login(username, password)
			.then(() => {
				navigate('/getting-started');
			})
			.catch((err: unknown) => {
				if (axios.isAxiosError(err)) {
					const axiosErr: AxiosError = err as AxiosError;

					setMessage(
						<Alert
							variant="danger"
							isInline
							isPlain
							title={
								axiosErr.response?.data
									? (axiosErr.response?.data as IZIApiError).message
									: 'Something went wrong, please contact the administrator'
							}
							data-testid="login-error"
						/>
					);

					setLoading(false);
				}
			});
	};

	const button = loading ? (
		<Spinner size={'lg'} />
	) : (
		<Button
			onClick={login}
			variant="primary"
			data-testid="login-submit"
		>
			Login
		</Button>
	);

	return (
		<CenteredPage
			description="Please enter your login credentials:"
			notice={message}
		>
			<Form>
				<FormGroup
					label="User Account:"
					isRequired
				>
					<TextInput
						isRequired
						type="text"
						id={'username'}
						aria-label={'User Account'}
						onChange={(e) => setUsername(e)}
						data-testid="login-username"
					></TextInput>
				</FormGroup>
				<FormGroup
					label="User Password:"
					isRequired
				>
					<TextInput
						isRequired
						type="password"
						id={'password'}
						aria-label={'User Password'}
						onChange={(e) => setPassword(e)}
						data-testid="login-password"
					></TextInput>
				</FormGroup>
				<ActionGroup>{button}</ActionGroup>
			</Form>
		</CenteredPage>
	);
}

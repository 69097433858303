import axiosConfig from '../axiosConfig';

export type TDatabaseServer = {
	charset: string;
	db_user: string;
	driver: string;
	host: string;
	id: number;
	name: string;
	password: string;
	port: number;
};

export type TNewDatabaseServer = Omit<TDatabaseServer, 'id'>;

export const databaseServerURL = 'admin/database-server';
export class DatabaseServer implements TDatabaseServer {
	public static Default(): TNewDatabaseServer {
		return {
			charset: 'utf8',
			db_user: '',
			driver: '',
			host: '',
			name: '',
			password: '',
			port: 3306,
		};
	}

	public static GetAll(expand: (keyof TDatabaseServer)[] = []): Promise<TDatabaseServer[]> {
		return axiosConfig.instance
			.get(databaseServerURL + String('s'), { params: { expand: expand } })
			.then((res) => {
				return (res.data as TDatabaseServer[]).map((entity: TDatabaseServer) => {
					return entity;
				});
			});
	}

	public static Delete(id: number): Promise<boolean> {
		return axiosConfig.instance.delete(`admin/system-user/` + String(id)).then(() => {
			return true;
		});
	}

	public static New(entity: TNewDatabaseServer): Promise<TDatabaseServer> {
		return axiosConfig.instance.post(databaseServerURL, entity).then((res) => {
			return res.data as TDatabaseServer;
		});
	}

	public static Update(entity: TDatabaseServer): Promise<TDatabaseServer> {
		return axiosConfig.instance.patch(databaseServerURL, entity).then((res) => {
			return res.data as TDatabaseServer;
		});
	}

	charset: string;
	db_user: string;
	driver: string;
	host: string;
	id: number;
	name: string;
	password: string;
	port: number;

	constructor(entity: TDatabaseServer) {
		this.charset = entity.charset;
		this.db_user = entity.db_user;
		this.driver = entity.driver;
		this.host = entity.host;
		this.id = entity.id;
		this.name = entity.name;
		this.password = entity.password;
		this.port = entity.port;
	}
}

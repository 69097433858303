import React, { ReactElement, useEffect } from 'react';
import {
	Page,
	PageSection,
	SidebarPanel,
	SidebarContent,
	Sidebar,
	List,
} from '@patternfly/react-core';
import Header from './header/Header';
import logo from './zi1 3zi-logo.png';
import './Layout.css';
import {
	faUserPolice,
	faServer,
	faStar,
	faHouseBlank,
	faBell,
} from '@fortawesome/pro-regular-svg-icons';
import ZISidebar, { INavigationLink } from './PageNavigationLinks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Outlet } from 'react-router-dom';
import ToastProvider from '@zeroedin-tech/zi-common-ui/lib/components/toast/ToastProvider';
import UserProvider from '../components/user/UserProvider';

export type SubheaderContext = ((arg0: ReactElement) => void)[];

export default function Layout(): ReactElement {
	const header = (
		<Header
			headerText={'ZeroedIn Analytics Platform'}
			logoImg={logo}
		/>
	);
	const [subheader, setSubheader] = React.useState(null);
	const [subheading, setSubheading] = React.useState<React.ReactElement | null>(null);

	const sidebarLinks: INavigationLink[] = [
		{
			title: 'Getting Started',
			icon: <FontAwesomeIcon icon={faStar} />,
			to: 'getting-started',
		},
		{
			title: 'Administrators',
			icon: <FontAwesomeIcon icon={faUserPolice} />,
			to: 'administrators',
		},
		{
			title: 'Database Servers',
			icon: <FontAwesomeIcon icon={faServer} />,
			to: 'database-servers',
		},
		{
			title: 'Zones',
			icon: <FontAwesomeIcon icon={faHouseBlank} />,
			to: 'zones',
		},
		{
			title: 'System Notifications',
			icon: <FontAwesomeIcon icon={faBell} />,
			to: 'system-notifications',
		},
	];

	useEffect(() => {
		if (subheader) {
			setSubheading(
				<PageSection
					style={{
						backgroundColor: 'var(--pf-global--palette--black-800)',
						minHeight: '125px',
					}}
				>
					{subheader}
				</PageSection>
			);
		}
	}, [subheader, setSubheader]);

	const sidebar = <ZISidebar sidebarLinks={sidebarLinks} />;

	return (
		<UserProvider>
			<Page header={header}>
				{subheading}
				<PageSection
					style={{
						minHeight: '500px',
						paddingTop: '0',
						paddingLeft: '0',
					}}
				>
					<Sidebar
						className="zi-sidebar"
						hasGutter
					>
						<SidebarPanel
							style={{
								borderRight: '1px solid grey',
								paddingTop: 'var(--pf-c-page__main-section--PaddingTop)',
							}}
							hasNoBackground
						>
							<List
								className="zi-sidebar-links"
								isPlain
							>
								{sidebar}
							</List>
						</SidebarPanel>
						<SidebarContent
							hasNoBackground
							style={{
								paddingTop: 'var(--pf-c-page__main-section--PaddingTop)',
							}}
						>
							<ToastProvider>
								<Outlet context={[setSubheader]} />
							</ToastProvider>
						</SidebarContent>
					</Sidebar>
				</PageSection>
			</Page>
		</UserProvider>
	);
}

import {
	ActionGroup,
	Alert,
	Button,
	Form,
	FormGroup,
	Spinner,
	TextInput,
} from '@patternfly/react-core';
import CenteredPage from '../../../layout/CenteredPage';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SystemUser } from '../../../api/systemUser/SystemUser';
import axiosConfig, { IZIApiError } from '../../../api/axiosConfig';
import axios, { AxiosError } from 'axios';
import { getCSRFToken } from '../../../api/security/CSRFToken';
import { faCheck, faEye, faEyeSlash, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './PasswordReset.css';

export default function PasswordReset() {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const passwordRef = useRef<HTMLInputElement>(null);
	const confirmPasswordRef = useRef<HTMLInputElement>(null);
	const [meter, setMeter] = useState(false);
	const [icon, setIcon] = useState({
		password: faEyeSlash,
		confirmPassword: faEyeSlash,
	});
	const [input, setInput] = useState({
		password: '',
		confirmPassword: '',
	});
	const [loading, setLoading] = useState<boolean>(false);
	const [notice, setNotice] = useState<React.ReactElement | null>(null);
	const [validRequest, setValidRequest] = useState<boolean>(true);
	const [error, setError] = useState({
		password: 'Please Enter New Password',
		confirmPassword: 'Please Enter Confirm Password',
	});
	const [type, setType] = useState({
		password: 'password',
		confirmPassword: 'password',
	});

	const atLeastOneUppercase = /[A-Z]/g;
	const atLeastOneLowercase = /[a-z]/g;
	const atLeastOneNumeric = /[0-9]/g;
	const atLeastOneSpecialChar = /[#?!@$%^&*-]/g;
	const betweenSixAndSixteenChars = /.{6,16}/g;

	const onInputChange = (value: string, event: React.FormEvent<HTMLInputElement>) => {
		const target = event.target as HTMLInputElement;
		const { name } = target;

		setInput((prev) => ({
			...prev,
			[name]: value,
		}));

		validateInput(value, target);
	};

	const validateInput = (value: string, target: HTMLInputElement) => {
		const { name } = target;

		setError((prev) => {
			const stateObj = { ...prev, [name]: '' };

			switch (name) {
				case 'password':
					if (!value) {
						stateObj[name] = 'Please Enter New Password';
					} else if (input.confirmPassword && value !== input.confirmPassword) {
						stateObj['confirmPassword'] =
							'Password and Confirm Password does not match';
					} else {
						stateObj['confirmPassword'] = input.confirmPassword
							? ''
							: error.confirmPassword;
					}
					break;

				case 'confirmPassword':
					if (!value) {
						stateObj[name] = 'Please Enter Confirm Password';
					} else if (input.password && value !== input.password) {
						stateObj[name] = 'Password and Confirm Password does not match';
					}
					break;

				default:
					break;
			}

			return stateObj;
		});
	};

	const toggleType = (event: React.MouseEvent<HTMLSpanElement>) => {
		const target = event.currentTarget;
		const { id } = target;

		setType((prev) => {
			const stateObj = { ...prev, [id]: '' };

			switch (id) {
				case 'password':
					if (prev.password === 'password') {
						stateObj[id] = 'text';
					} else {
						stateObj[id] = 'password';
					}
					break;

				case 'confirmPassword':
					if (prev.confirmPassword === 'password') {
						stateObj[id] = 'text';
					} else {
						stateObj[id] = 'password';
					}
					break;

				default:
					break;
			}

			return stateObj;
		});

		setIcon((prev) => {
			const stateObj = { ...prev, [id]: '' };

			switch (id) {
				case 'password':
					if (prev.password === faEyeSlash) {
						stateObj[id] = faEye;
					} else {
						stateObj[id] = faEyeSlash;
					}
					break;

				case 'confirmPassword':
					if (prev.confirmPassword === faEyeSlash) {
						stateObj[id] = faEye;
					} else {
						stateObj[id] = faEyeSlash;
					}
					break;

				default:
					break;
			}

			return stateObj;
		});
	};

	const matchPasswordLength = (r: RegExp, password: string) => {
		const match = password.match(r);
		return match && match.length == 1;
	};

	const passwordTracker = {
		uppercase: input.password.match(atLeastOneUppercase),
		lowercase: input.password.match(atLeastOneLowercase),
		number: input.password.match(atLeastOneNumeric),
		specialChar: input.password.match(atLeastOneSpecialChar),
		betweenSixAndSixteenChars: matchPasswordLength(betweenSixAndSixteenChars, input.password),
	};

	const passwordStrength = Object.values(passwordTracker).filter((value) => value).length;

	const getStrengthColor = (score: number) => {
		switch (score) {
			case 1:
				return '#ff4d4d';
			case 2:
				return '#ff8533';
			case 3:
				return '#ffcc00';
			case 4:
				return '#99cc00';
			case 5:
				return 'green';
			default:
				return '#f5f5f5';
		}
	};

	const getStrengthText = (score: number) => {
		switch (score) {
			case 1:
				return 'Weak';
			case 2:
				return 'Fair';
			case 3:
				return 'OK';
			case 4:
				return 'Good';
			case 5:
				return 'Strong';
			case 6:
				return 'Strong';
		}
	};

	useEffect(() => {
		getCSRFToken()
			.then((token) => {
				axiosConfig.instance.defaults.headers['CSRF-TOKEN'] = token;
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	useEffect(() => {
		if (
			!searchParams.get('validation_key') ||
			!searchParams.get('user_id') ||
			searchParams.get('validation_key') === '' ||
			searchParams.get('user_id') === ''
		) {
			setValidRequest(false);

			setNotice(
				<Alert
					variant="danger"
					isInline
					isPlain
					title="The request is invalid. Please try again or contact your system administrator."
					data-testid="passwordreset-invalidrequest"
				/>
			);
		}
	}, [searchParams]);

	const resetPassword = () => {
		if (loading) {
			return;
		}

		const password = passwordRef.current?.value;
		const user_id = searchParams.get('user_id');
		const validation = searchParams.get('validation_key');

		if (user_id === null || validation === null) {
			return;
		}

		setNotice(null);
		setLoading(true);

		const userId = parseInt(user_id);

		if (password) {
			SystemUser.ResetPassword(userId || -1, password, validation)
				.then(() => {
					setNotice(
						<Alert
							variant="success"
							isInline
							isPlain
							title="Successfully updated password. Redirecting to login..."
							data-testid="passwordreset-success"
						/>
					);

					setValidRequest(false);

					setTimeout(() => {
						navigate('/login');
					}, 3 * 1000);
				})
				.catch((err: unknown) => {
					if (axios.isAxiosError(err)) {
						const axiosErr: AxiosError = err as AxiosError;

						setNotice(
							<Alert
								variant="danger"
								isInline
								isPlain
								title={
									axiosErr.response?.data
										? (axiosErr.response?.data as IZIApiError).message
										: 'Something went wrong, please contact the administrator'
								}
								data-testid="passwordreset-error"
							/>
						);

						setLoading(false);
					}
				});
		}
	};

	if (!validRequest) {
		return (
			<CenteredPage
				description="The request is invalid."
				notice={notice}
			>
				<Button
					onClick={() => {
						navigate('/login');
					}}
					variant="primary"
					data-testid="passwordreset-login"
				>
					Return to Login
				</Button>
			</CenteredPage>
		);
	}

	return (
		<CenteredPage
			description="Please enter your new password:"
			notice={notice}
			resetWidth={true}
		>
			<Form>
				<FormGroup
					label="New Password:"
					isRequired
					fieldId="newPassword"
					helperTextInvalid={error.password}
					helperTextInvalidIcon={<>*</>}
					validated={error.password ? 'error' : 'default'}
				>
					<TextInput
						isRequired
						isDisabled={!validRequest}
						onFocus={() => setMeter(true)}
						onChange={onInputChange}
						type={type.password === 'password' ? type.password : 'text'}
						name="password"
						id="password"
						aria-label="New Password"
						data-testid="passwordreset-password"
						autoComplete="new-password"
						ref={passwordRef}
					/>
					<span className="p-validate">
						{error.password === '' ? (
							<FontAwesomeIcon
								icon={faCheck}
								className="fa-check"
							/>
						) : (
							<FontAwesomeIcon
								icon={faXmark}
								className="fa-x"
							/>
						)}
					</span>
					<span
						id="password"
						className="p-viewer"
						onClick={toggleType}
					>
						<FontAwesomeIcon icon={icon.password} />
					</span>
				</FormGroup>
				<FormGroup
					label="Confirm New Password:"
					isRequired
					fieldId="confirmPassword"
					helperTextInvalid={error.confirmPassword}
					helperTextInvalidIcon={<>*</>}
					validated={error.confirmPassword ? 'error' : 'default'}
				>
					<TextInput
						isRequired
						isDisabled={!validRequest}
						onChange={onInputChange}
						type={type.confirmPassword === 'password' ? type.confirmPassword : 'text'}
						name="confirmPassword"
						id="confirm-password"
						aria-label={'Confirm New Password'}
						data-testid="passwordreset-confirmpassword"
						autoComplete="new-password"
						ref={confirmPasswordRef}
					></TextInput>
					<span className="p-validate">
						{error.confirmPassword === '' ? (
							<FontAwesomeIcon
								icon={faCheck}
								className="fa-check"
							/>
						) : (
							<FontAwesomeIcon
								icon={faXmark}
								className="fa-x"
							/>
						)}
					</span>
					<span
						id="confirmPassword"
						className="p-viewer"
						onClick={toggleType}
					>
						<FontAwesomeIcon icon={icon.confirmPassword} />
					</span>
				</FormGroup>

				{meter && (
					<>
						<span>Password Strength Indicator</span>
						<div
							className="password-strength"
							style={{
								borderColor: getStrengthColor(passwordStrength),
							}}
						>
							<div
								className="password-strength-meter"
								style={{
									width: `${(passwordStrength / 5) * 100}%`,
									backgroundColor: getStrengthColor(passwordStrength),
								}}
							>
								<p>{getStrengthText(passwordStrength)}</p>
							</div>
						</div>
					</>
				)}

				<div>
					<p>
						{passwordTracker.uppercase ? (
							<FontAwesomeIcon
								icon={faCheck}
								color="green"
								className="fontAwesomePadding fa-check"
							/>
						) : (
							<FontAwesomeIcon
								icon={faXmark}
								color="red"
								className="fontAwesomePadding fa-x"
							/>
						)}
						MUST contain at least one uppercase letter
					</p>
					<p>
						{passwordTracker.lowercase ? (
							<FontAwesomeIcon
								icon={faCheck}
								color="green"
								className="fontAwesomePadding fa-check"
							/>
						) : (
							<FontAwesomeIcon
								icon={faXmark}
								color="red"
								className="fontAwesomePadding fa-x"
							/>
						)}
						MUST contain at least one lowercase letter
					</p>
					<p>
						{passwordTracker.number ? (
							<FontAwesomeIcon
								icon={faCheck}
								color="green"
								className="fontAwesomePadding fa-check"
							/>
						) : (
							<FontAwesomeIcon
								icon={faXmark}
								color="red"
								className="fontAwesomePadding fa-x"
							/>
						)}
						MUST contain at least one number
					</p>
					<p>
						{passwordTracker.specialChar ? (
							<FontAwesomeIcon
								icon={faCheck}
								color="green"
								className="fontAwesomePadding fa-check"
							/>
						) : (
							<FontAwesomeIcon
								icon={faXmark}
								color="red"
								className="fontAwesomePadding fa-x"
							/>
						)}
						MUST contain at least one special character
					</p>
					<p>
						{passwordTracker.betweenSixAndSixteenChars ? (
							<FontAwesomeIcon
								icon={faCheck}
								color="green"
								className="fontAwesomePadding fa-check"
							/>
						) : (
							<FontAwesomeIcon
								icon={faXmark}
								color="red"
								className="fontAwesomePadding fa-x"
							/>
						)}
						MUST contain between 6 and 16 characters
					</p>
				</div>

				<ActionGroup>
					{loading ? (
						<Spinner size={'lg'} />
					) : (
						<Button
							onClick={resetPassword}
							variant="primary"
							isDisabled={
								!validRequest ||
								passwordStrength < 5 ||
								input.password !== input.confirmPassword
							}
							data-testid="passwordreset-submit"
						>
							Reset Password
						</Button>
					)}
				</ActionGroup>
			</Form>
		</CenteredPage>
	);
}

import axiosConfig from '../axiosConfig';

export type TSystemNotification = {
	id: number;
	end_date: number;
	start_date: number;
	message: string;
};

export type TNewSystemNotification = Omit<TSystemNotification, 'id'>;

export const systemNotificationURL = 'admin/system-notification';

export class SystemNotification implements TSystemNotification {
	id: number;
	message: string;
	start_date: number;
	end_date: number;

	constructor(properties: TSystemNotification) {
		this.id = properties.id;
		this.message = properties.message;
		this.start_date = properties.start_date;
		this.end_date = properties.end_date;
	}

	public static Default(): TNewSystemNotification {
		return {
			end_date: Date.now() / 1000,
			message: '',
			start_date: Date.now() / 1000,
		};
	}

	public static GetAll(): Promise<TSystemNotification[]> {
		return axiosConfig.instance.get(systemNotificationURL + String('s')).then((res) => {
			return ((res.data || []) as TSystemNotification[]).map(
				(entity: TSystemNotification) => {
					return entity;
				}
			);
		});
	}

	public static Delete(id: number): Promise<boolean> {
		return axiosConfig.instance.delete(`${systemNotificationURL}/${String(id)}`).then(() => {
			return true;
		});
	}

	public static Update(entity: TSystemNotification): Promise<TSystemNotification> {
		return axiosConfig.instance.patch(systemNotificationURL, entity).then((res) => {
			return res.data as TSystemNotification;
		});
	}

	public static New(entity: TNewSystemNotification): Promise<TSystemNotification> {
		return axiosConfig.instance.post(systemNotificationURL, entity).then((res) => {
			return res.data as TSystemNotification;
		});
	}
}

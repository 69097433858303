import axiosConfig from '../axiosConfig';

export type TSystemUser = {
	id: number;
	account: string;
	appUsers: unknown[];
	email: string;
	failed_logins: number; // Default 0
	force_logout: boolean; // Default false
	last_failed_login: number | null; // Default null
	last_failed_login_ip: string | null; // Default null
	last_login: number | null; // Default null
	last_login_ip: string | null; // Default null
	must_change_password: boolean; // Default false
	name: string;
	password: string;
	status: string;
};

export type TNewSystemUser = Omit<
	TSystemUser,
	| 'id'
	| 'failed_logins'
	| 'last_failed_login'
	| 'last_failed_login_ip'
	| 'last_login'
	| 'last_login_ip'
>;

export class SystemUser implements TSystemUser {
	account: string;
	appUsers: unknown[];
	email: string;
	failed_logins: number;
	force_logout: boolean;
	id: number;
	last_failed_login: number | null;
	last_failed_login_ip: string | null;
	last_login: number | null;
	last_login_ip: string | null;
	must_change_password: boolean;
	name: string;
	password: string;
	status: string;

	constructor(systemUser: TSystemUser) {
		this.account = systemUser.account;
		this.appUsers = systemUser.appUsers;
		this.email = systemUser.email;
		this.failed_logins = systemUser.failed_logins;
		this.force_logout = systemUser.force_logout;
		this.id = systemUser.id;
		this.last_failed_login = systemUser.last_failed_login;
		this.last_failed_login_ip = systemUser.last_failed_login_ip;
		this.last_login = systemUser.last_login;
		this.last_login_ip = systemUser.last_login_ip;
		this.must_change_password = systemUser.must_change_password;
		this.name = systemUser.name;
		this.password = systemUser.password;
		this.status = systemUser.status;
	}

	public static Me(): Promise<TSystemUser> {
		return axiosConfig.instance.get('admin/me').then((response) => {
			return response.data as TSystemUser;
		});
	}

	public static Logout(): Promise<void> {
		return axiosConfig.instance.post('/logout');
	}

	public static Default(): TNewSystemUser {
		return {
			account: '',
			appUsers: [],
			email: '',
			force_logout: false,
			must_change_password: false,
			name: '',
			password: '',
			status: 'Active',
		};
	}

	public static RequestResetPassword(entity: TSystemUser): Promise<void> {
		return axiosConfig.instance.post('admin/system-user/request-reset-password', {
			id: entity.id,
		});
	}

	public static ResetPassword(
		id: number,
		newPassword: string,
		validationKey: string,
		current_password?: string
	): Promise<void> {
		return axiosConfig.instance.post('admin/system-user/reset-password', {
			id,
			new_password: newPassword,
			validation_key: validationKey,
			current_password,
		});
	}

	public static ClearPasswordHistory(entity: TSystemUser): Promise<void> {
		return axiosConfig.instance.post('admin/system-user/clear-password-history', {
			id: entity.id,
		});
	}

	public static GetAll(): Promise<TSystemUser[]> {
		// This requires multiple return endpoints to end in 's' in the API
		return axiosConfig.instance.get('admin/system-users').then((res) => {
			return (res.data as TSystemUser[]).map((entity) => {
				return entity;
			});
		});
	}

	public static Delete(id: number): Promise<boolean> {
		return axiosConfig.instance.delete(`admin/system-user/` + String(id)).then(() => {
			return true;
		});
	}

	public static New(entity: TNewSystemUser): Promise<TSystemUser> {
		return axiosConfig.instance.post('admin/system-user', entity).then((res) => {
			return res.data as TSystemUser;
		});
	}

	public static Update(entity: TSystemUser): Promise<TSystemUser> {
		return axiosConfig.instance.patch('admin/system-user', entity).then((res) => {
			return res.data as TSystemUser;
		});
	}
}

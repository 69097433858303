import React, { createContext, type ReactNode, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Bullseye, Spinner } from '@patternfly/react-core';
import { SystemUser, TSystemUser } from '../../api/systemUser/SystemUser';
import { getCSRFToken } from '../../api/security/CSRFToken';
import axiosConfig from '../../api/axiosConfig';

export type UseUserHook = TSystemUser;

const UserContext = createContext<UseUserHook>(SystemUser.Default() as TSystemUser);
const { Provider } = UserContext;

type Props = {
	children: ReactNode;
};
export default function UserProvider(props: Props) {
	const [userLoaded, setUserLoaded] = useState<boolean>(false);
	const [user, setUser] = useState<TSystemUser>(SystemUser.Default() as TSystemUser);
	const navigate = useNavigate();

	useEffect(() => {
		const initialize = async () => {
			if (userLoaded) {
				return;
			}

			const token = await getCSRFToken();
			axiosConfig.instance.defaults.headers['CSRF-TOKEN'] = token;

			SystemUser.Me()
				.then((me) => {
					setUser(me);
					setUserLoaded(true);
				})
				.catch(() => {
					navigate('/login');
				});
		};
		void initialize();
	});

	if (!userLoaded) {
		return (
			<Bullseye>
				<div>
					<Spinner
						isSVG
						size={'xl'}
					/>
				</div>
			</Bullseye>
		);
	}

	return <Provider value={user}>{props.children}</Provider>;
}

export const useUser: () => UseUserHook = () => {
	const ctx = useContext<UseUserHook>(UserContext);

	if (!ctx) {
		throw Error('The `useUser` hook must be called from a descendent of the `UserProvider`.');
	}

	return ctx;
};

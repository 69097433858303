import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-duotone-svg-icons';
import { Dropdown, DropdownItem, Icon } from '@patternfly/react-core';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SystemUser } from '../../api/systemUser/SystemUser';

export default function Avatar() {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const navigate = useNavigate();

	const logout = () => {
		console.log('Logging out');
		SystemUser.Logout()
			.then(() => {
				navigate('/login');
			})
			.catch(() => {
				navigate('/login');
			});
	};
	const onToggle = (isOpen: boolean) => {
		setIsOpen(isOpen);
	};

	const onFocus = () => {
		const el = document.getElementById('user-action-toggle');

		if (!el) {
			return;
		}

		el.focus();
	};

	const onSelect = () => {
		setIsOpen(!isOpen);
		onFocus();
	};

	return (
		<Dropdown
			position="right"
			isPlain
			onSelect={onSelect}
			isOpen={isOpen}
			dropdownItems={[
				<DropdownItem
					key="logout"
					onClick={logout}
					data-testid="user-logout-button"
				>
					Log Out
				</DropdownItem>,
			]}
			style={{
				width: '1.5rem',
				height: '1.5rem',
				borderRadius: '50%',
			}}
			toggle={
				<Icon
					onClick={() => onToggle(!isOpen)}
					style={{
						width: '1.5rem',
						height: '1.5rem',
						color: '#8A8D90',
						cursor: 'pointer',
					}}
					alt={'profile'}
					isInline
					data-testid="user-avatar-dropdown"
				>
					<FontAwesomeIcon icon={faUser} />
				</Icon>
			}
		/>
	);
}

import { Brand, Bullseye, Flex, FlexItem } from '@patternfly/react-core';
import logo from '../routes/root/login/logo.png';
import React from 'react';
export type Props = {
	children: React.ReactNode;
	description: string;
	notice: React.ReactNode;
	resetWidth?: boolean;
};

export default function CenteredPage(props: Props) {
	return (
		<Bullseye className="pf-u-justify-content-center">
			<Flex
				direction={{ default: 'column' }}
				flex={{ default: 'flex_2' }}
				style={props.resetWidth ? {} : { width: '356px' }}
			>
				<FlexItem>
					<Flex
						direction={{ default: 'column' }}
						flex={{ default: 'flex_1' }}
						width={'100%'}
						alignItems={{ default: 'alignItemsCenter' }}
					>
						<FlexItem>
							<Brand
								className={'large-brand-logo'}
								src={logo}
								alt={'ZeroedIn Technologies'}
							></Brand>
							<h1
								style={{
									textAlign: 'center',
									fontSize: '1.35rem',
									fontWeight: '400',
								}}
							>
								Administration Console
							</h1>
						</FlexItem>
						<FlexItem>
							<div>{props.description}</div>
						</FlexItem>
						<FlexItem>{props.notice}</FlexItem>
					</Flex>
				</FlexItem>
				<FlexItem>{props.children}</FlexItem>
			</Flex>
		</Bullseye>
	);
}
